import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CountUp from "react-countup"
import ScrollTrigger from "react-scroll-trigger"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Carousel from "react-bootstrap/Carousel"
import {
  Title,
  SubTitle,
  Paragraph,
  RenderAstHomePage,
} from "../components/styledComponents"
import "../components/navigator.css"
import { Stack } from "react-bootstrap"

export default function HomePage({ data }) {
  const images = data.topImageSlider.nodes
  const projects = data.projects.nodes
  const lastProjects = projects.slice(0, 3)
  const misiuneaNoastra = data.misiuneaNoastra
  const rezultate = data.rezultate.nodes
  const parteneri = data.parteneri.nodes

  const [counterOn, setCounterOn] = useState(false)

  return (
    <Layout>
      <Seo
        title="Home"
        description={undefined}
        lang={undefined}
        meta={undefined}
      />
      <Carousel slide={false} className="flex h-[87%] ">
        {images.map(image => (
          <Carousel.Item key={image.id} className="h-[50%]">
            <GatsbyImage
              className="animate-wiggle h-screen brightness-50"
              image={image.featuredImg.childImageSharp.gatsbyImageData}
              alt="bla"
            />
            <Carousel.Caption>
              <Title className="text-white pb-24">
                {image.frontmatter.title}
              </Title>
              <button
                className="text-white bg-[#264248] hover:bg-[#FDAE38] transition ease-in-out delay-150 hover:scale-110 duration-500 focus:ring-4 font-large rounded-lg text-xl w-full sm:w-auto px-5 py-2.5 text-center"
                type="button"
                onClick={e => {
                  e.preventDefault()
                  window.location.href = image.frontmatter.path
                }}
              >
                {image.frontmatter.buttonName}
              </button>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="bg-[#4FA3A5]">
        <Title className="text-center text-white p-5">Ultimele Proiecte</Title>
        <div className="grid justify-items-center ...">
          <div className="grid  grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-6  w-[80%]">
            {lastProjects.map(project => (
              <a
                href={"/projects/" + project.frontmatter.path}
                key={project.id}
              >
                <div className="bg-white shadow-2xl rounded-3xl h-[100%]">
                  <GatsbyImage
                    className="rounded-t-3xl w-full aspect-video ..."
                    image={project.featuredImg.childImageSharp.gatsbyImageData}
                    alt="bla"
                  />
                  <div className="py-3 w-10/12 items-center mx-auto">
                    <SubTitle className="no-underline text-gray-900">
                      {project.frontmatter.title}
                    </SubTitle>
                    <Paragraph className="text-gray-700 py-3 no-underline">
                      {project.frontmatter.description}
                    </Paragraph>
                    <button
                      className="no-underline text-white bg-[#264248] hover:bg-[#FDAE38] focus:ring-4 rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
                      onClick={e => {
                        e.preventDefault()
                        const path = "/projects/" + project.frontmatter.path
                        window.location.href = path
                      }}
                      key={project.id}
                    >
                      Read more
                    </button>
                  </div>
                </div>
              </a>
            ))}
          </div>
          <div className="mx-auto py-16">
            <button
              className="text-white bg-[#264248] hover:bg-[#FDAE38] focus:ring-4 focus:outline-none font-large rounded-lg text-xl w-full sm:w-auto px-5 py-2.5 text-center"
              type="button"
              onClick={e => {
                e.preventDefault()
                window.location.href = "/projects"
              }}
            >
              Vezi toate proiectele
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#264248] p-5 items-center text-white text-center">
        <div className="ml-auto mr-auto w-8/12">
          <Title className="text-white pb-5">
            {misiuneaNoastra.frontmatter.title}
          </Title>
          {RenderAstHomePage(misiuneaNoastra.htmlAst)}
        </div>
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
        >
          <div className="container mx-auto pt-16">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-16 items-center text-white">
              {rezultate.map(rezultat => (
                <div
                  key={rezultat.id}
                  className="flex flex-col justify-center items-center mx-auto gap-8 pt-8"
                >
                  <GatsbyImage
                    className="flex items-center mx-auto"
                    image={rezultat.featuredImg.childImageSharp.gatsbyImageData}
                    alt="bla"
                  />
                  <Title className="font-extrabold text-center text-[100px]">
                    {counterOn && <CountUp end={rezultat.frontmatter.number} />}
                    {rezultat.frontmatter.hasPlus && "+"}
                  </Title>
                  <Paragraph className="font-extrabold text-center">
                    {rezultat.frontmatter.description}
                  </Paragraph>
                </div>
              ))}
            </div>
          </div>
        </ScrollTrigger>
      </div>
      <div className="bg-gradient-to-b from-[#4FA3A5]  to-white">
        <div className="mx-auto w-5/12 lg:w-8/12 xl:w-8/12 py-12">
          <Title className="pb-12">Parteneri</Title>
          <div className="grid grid-rows-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 gap-8 items-center">
            {parteneri.map(partener => (
              <div key={partener.id}>
                <a
                  href={partener.frontmatter.site}
                  target="_blank"
                  rel="noreferrer"
                >
                  <GatsbyImage
                    image={partener.featuredImg.childImageSharp.gatsbyImageData}
                    alt={partener.frontmatter.site}
                    className="scale-80 transition ease-in-out hover:scale-100 duration-500"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const homeQuery = graphql`
  query HomePage {
    topImageSlider: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/home/topImage/" } }
    ) {
      nodes {
        id
        frontmatter {
          path
          title
          buttonName
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
              placeholder: BLURRED
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/projects/" } }
    ) {
      nodes {
        id
        frontmatter {
          path
          title
          description
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { fit: COVER, cropFocus: CENTER }
              placeholder: BLURRED
            )
          }
        }
      }
    }
    misiuneaNoastra: markdownRemark(
      fileAbsolutePath: { regex: "/home/misiuneaNoastra/" }
    ) {
      htmlAst
      frontmatter {
        title
      }
    }
    rezultate: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/home/rezultate/" } }
    ) {
      nodes {
        id
        frontmatter {
          number
          hasPlus
          description
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
              placeholder: BLURRED
              layout: FIXED
              width: 100
              height: 100
            )
          }
        }
      }
    }
    parteneri: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/home/sponsori/" } }
    ) {
      nodes {
        id
        frontmatter {
          site
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
              placeholder: BLURRED
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`
